<template>
  <div class="box">
    <div class="left leftDetail">
      <img class="leftBg" v-if="!id" src="../assets/images/Partners.png">
      <div class="leftImg">
        <navHead />
        <div class="leftTxt" v-if="!id">
          Partners
        </div>
      </div>
    </div>
    <div class="right rightDetail" :class="{'detail' : id}">
      <div class="rightTitle" v-if="!id">
        <p>We have significant experience of helping businesses expand into the Chinese market.<br />Through our expertise and high performance, we help your business maximize its profit margins and, through client-focused consultancy services, specifically support, to the highest standards, your international expansion into the China-Asian Pacific market.</p>
        <div class="imgBox">
          <img src="../assets/images/Partnersimg.png">
        </div>
        <p>A world leading company specialising in pressure vessel solutions. It is part of the Iv-Groep.  LIONOX began working with Ecsher in 2000.  During the last two decades we have broadened and deepened our collaboration.  LIONOX has assisted Ecsher develop its busines in China by managing supplement control and by integrating its business into the Chinese business environment.</p>
        <div class="imgBox">
          <b>TUV Nord，SGS，Det Norske Veritas，Bureou Veritas</b>
        </div>
        <p>LIONOX is the authorised partner in China for the world leading inspection companies.</p>
        <div class="imgBox">
          <img src="../assets/images/Partnersimg3.png">
        </div>
        <p class="borderLine">WPEC, a China’s leading high pressure vessels manufacture, it has been working with Lionox for a very long period, and worked on many fascinating projects globally.</p>
      </div>
      <video src="http://lionox.co/cpanel/wpec.mp4" controls="controls"></video>
    </div>
  </div>
</template>

<script>

import navHead from '/src/components/navHead'

export default {
  name: 'Partners',
  components: {
    navHead
  },
  data(){
    return{
      id: null
    }
  },
  mounted(){
    this.id = this.$route.query.id || null
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.rightUl{
  padding-top: 30px;
}
.imgBox img{
  display: block;
  margin: 30px 0
}
.rightTitle p{
  border-bottom: 2px solid #231f20;
  padding-bottom: 30px;
}
.rightTitle b{
  display: block;
  margin-bottom: 30px;
}
.rightTitle .borderLine{
  border:0;
}
.rightDetail video{
  width: 100%;
  margin-top: 30px;
}
</style>
